<template>
  <div class="head">
    <!--  标题  65-->
    <div class="bt">
      <p class="bt1">安康市智慧康养云服务平台指挥监控大屏</p>
    </div>

    <!--  内容  -->
    <div class="middle">
      <div class="m-left">
        <!--    招生人次      -->

        <!--    服务投诉明细/日    --><!-- <border>组件是淡色边框容器组件  -->
        <div style=" height: calc((100% - 20px) * 0.33);position: relative;margin-top: 10px">
          <div class="title" style="cursor: pointer" @click="tableChange(0)">{{ tableTitles[leftTableNumbers[0]] }}</div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap">
<!--小表格 开始-->

            <!--    服务投诉明细/日    -->
            <gpgezfxmmx :date-daily="dateDaily" :dateDailyYc="dateDailyYc" :dateDailyTs="dateDailyTs" :dateDailyGPGE="dateDailyGPGE"  :animate-up="animateUp" :table-number="leftTableNumbers[0]"></gpgezfxmmx>
            <!--    服务投诉明细/日    -->

<!--小表格 结束-->
          </div>
        </div>

        <!--    服务投诉明细/日    --><!-- <border>组件是淡色边框容器组件  -->
        <div style=" height: calc((100% - 20px) * 0.33);position: relative;margin-top: 10px">
          <div class="title" style="cursor: pointer" @click="tableChange(1)">{{ tableTitles[leftTableNumbers[1]] }}</div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap">

<!--            服务投诉明细/日-->
<!--            小表格 开始-->
            <!--表 服务投诉明细/日 开始-->
            <gpgezfxmmx :date-daily="dateDaily" :dateDailyYc="dateDailyYc" :dateDailyTs="dateDailyTs"  :dateDailyGPGE="dateDailyGPGE"  :animate-up="animateUp" :table-number="leftTableNumbers[1]"></gpgezfxmmx>
            <!--表 服务投诉明细/日 结束-->
<!--            小表格 结束-->
          </div>
        </div>
        <div style="height: calc((100% - 20px) * 0.33);position: relative;margin-top: 10px ">
          <!--    高频高额自费项目明细    -->
          <div class="title" style="cursor: pointer" @click="tableChange(2)">{{ tableTitles[leftTableNumbers[2]] }}</div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap">
<!--            小表格 开始-->
            <!--表 高频高额自费项目明细 开始-->
            <gpgezfxmmx :date-daily="dateDaily" :dateDailyYc="dateDailyYc" :dateDailyTs="dateDailyTs" :dateDailyGPGE="dateDailyGPGE"  :animate-up="animateUp" :table-number="leftTableNumbers[2]"></gpgezfxmmx>
            <!--表 高频高额自费项目明细 结束-->
<!--小表格 结束-->
          </div>
        </div>
      </div>
      <div class="m-middle">
        <!--        中间图-->
        <div style="" class="org">
          <div class="desc-wrap" style="">
            <!--     机构服务人次/年       -->
            <div class="desc">
              <p align="center">
                <span class="number">{{centreTop.orderJGVO.number}} </span>
                <span>&nbsp;</span>
                <span style="color: springgreen;font-size:11px">{{centreTop.orderJGVO.sz}}</span>
              </p>
              <p slot="title" align="center" class="desc-title">机构服务人次/年</p>
            </div>

            <!--   社区服务人次/年  -->
            <div class="desc">
              <p align="center">
                <span class="number">{{centreTop.orderSQVO.number}}</span>
                <span>&nbsp;</span>
                <span style="color: springgreen;font-size:11px">{{centreTop.orderSQVO.sz}}</span>
              </p>
              <p slot="title" align="center" class="desc-title">社区服务人次/年</p>
            </div>

            <!--      居家服务人次/年      -->
            <div class="desc">
              <p align="center">
                <span class="number">{{centreTop.orderJJVO.number}} </span>
                <span>&nbsp;</span>
                <span style="color: springgreen;font-size:11px">{{centreTop.orderJJVO.sz}}</span>
              </p>
              <p slot="title" align="center" class="desc-title">居家服务人次/年</p>
            </div>
            <!--      服务投诉人次/年      -->
            <div class="desc">
              <p align="center">
                <span class="number">{{centreTop.orderTSVO.number}} </span>
                <span>&nbsp;</span>
                <span style="color: springgreen;font-size:11px">{{centreTop.orderTSVO.sz}}</span>
              </p>
              <p slot="title" align="center" class="desc-title">服务投诉人次/年</p>
            </div>
          </div>
          <div class="desc-wrap desc-wrap-row2" style="">
            <div class="desc desc-row2">
              <yibiaopan chart-id="gauge1" :title="'工单人次/日'" :alert="false" :number="centreTop.orderGDVO.number"
                         :color1="'#5CF9FE'" :color2="'#468EFD'" :linecolor="'#648fd0'"></yibiaopan>
              <barP chart-id="xxx1" ref="barP" style="width: 100%" :sm="centreTop.orderGDVO.sm" :jg="centreTop.orderGDVO.jg"></barP>
            </div>
<!--            centreTop.orderGDVO.sm-->
            <div class="desc desc-row2">
              <yibiaopan chart-id="gauge2" :title="'正常工单人次/日'" :alert="false" :number="zcGdNumber"
                         :color1="'#46c78b'" :color2="'#19867d'" :linecolor="'#13766d'" ></yibiaopan>
              <barP2 chart-id="xxx2" ref="barP2" style="width: 100%" :sm="centreTop.orderZCVO.sm" :jg="centreTop.orderZCVO.jg"></barP2>
            </div>
            <div class="desc desc-row2" @click="cancelAlert">
<!--              <yibiaopan chart-id="gauge3" :title="'异常工单人次/日'" :alert="testFlag" :number="centreTop.orderYCVO.number"-->
              <yibiaopan chart-id="gauge3" :title="'异常工单人次/日'" :alert="false" :number="centreTop.orderYCVO.number"
                         :color1="'#e695ff'" :color2="'#a3418e'" :linecolor="'#aa3391'"
              ></yibiaopan>
              <barP3 chart-id="xxx3"  ref="barP3" style="width: 100%" :sm="centreTop.orderYCVO.sm" :jg="centreTop.orderYCVO.jg"></barP3>
            </div>
          </div>
        </div>
        <div ref="tableContent" style="height: calc((100% - 10px)  * 0.62);position: relative;margin-top: 10px">
          <!--    列表    -->
          <border style="width: 100%;height: 100%"/>
          <div style=" position: absolute;
    top: 14px;
    color: rgb(255, 255, 255);
    width: 100%;
    text-align: center;
    font-size: 28px;
    line-height: 40px;
    height: 40px;">{{ tableTitles[tableNumber] }}</div>

          <div class="echart-wrap">
<!--表 每日工单流水信息明细表 开始-->
            <div v-if="tableNumber === 0" style="width: calc(100% - 10px);height: calc(100% - 10px);overflow-y: scroll;" @mouseover="mouseAction(0)" @mouseleave="mouseAction(1)">
              <div style="width: 100%;display: flex;color: #fff;justify-content: space-around">
                <div style="width: 8.2%;" class="table-cell" >工单日期</div>
                <div style="width: 9.2%;" class="table-cell" >服务项目</div>
                <div style="width: 8.9%;" class="table-cell" >服务对象</div>
                <div style="width: 9%;" class="table-cell" >服务者</div>
<!--                <div style="width: 7.3%;" class="table-cell" >健康等级</div>-->
                <div style="width: 9.2%;" class="table-cell" >服务机构</div>
<!--                <div style="width: 7.3%;" class="table-cell" >区域</div>-->
                <div style="width: 5%;" class="table-cell" >计划时长</div>
                <div style="width: 14%;" class="table-cell" >开始时间</div>
                <div style="width: 14%;" class="table-cell" >完成时间</div>
                <div style="width: 4.5%;" class="table-cell" >异常</div>
                <div style="width: 4.5%;" class="table-cell" >机构</div>
                <div style="width: 4.5%;" class="table-cell" >上门</div>

              </div>
              <div style="height: calc(100% - 40px);overflow-y: scroll;" id="mainTable">
                <div :class="{ 'animate-up': animateUp}" style="
              width: 100%;
              display: flex;
              color: #fff;
              justify-content: space-around;" v-for="(item, index) in dateDaily" :key="index">
                  <div style="width: 8%;" class="table-cell">{{item.serviceDate}}</div>
                  <div style="width: 9%;" class="table-cell">{{item.measuresNursingName}}</div>
                  <div style="width: 8.9%;" class="table-cell">{{item.customerName}}</div>
                  <div style="width: 9%;" class="table-cell">{{item.providerName}}</div>
<!--                  <div style="width: 7.3%;" class="table-cell" ></div>-->
                  <div style="width: 10%;" class="table-cell">{{item.communityName}}</div>
<!--                  <div style="width: 7.3%;" class="table-cell" ></div>-->
                  <div style="width: 5%;" class="table-cell">{{item.duration}}</div>
                  <div style="width: 14%;" class="table-cell">{{item.startTime}}</div>
                  <div style="width: 14%;" class="table-cell">{{item.endTime}}</div>
                  <div style="width: 4.5%;" class="table-cell"></div>
                  <div style="width: 4.5%;" class="table-cell">{{item.sourceId == 1 ? '★' : ''}}</div>
                  <div style="width: 4.5%;" class="table-cell">{{item.sourceId == 2 ? '★' : ''}}</div>
                </div>
              </div>
            </div>
<!--表 每日工单流水信息明细表 结束-->

            <!--表 异常工单人次/日 开始-->
            <div v-if="tableNumber === 1" style="width: calc(100% - 10px);height: calc(100% - 10px);overflow-y: scroll;" @mouseover="mouseAction(0)" @mouseleave="mouseAction(1)">
              <div style="width: 100%;display: flex;color: #fff;justify-content: space-around">
                <div style="width: 10.66%;" class="table-cell">工单日期</div>
                <div style="width: 12.66%;" class="table-cell">服务项目</div>
                <div style="width: 10.66%;" class="table-cell">服务对象</div>
                <div style="width: 10.66%;" class="table-cell">服务者</div>
                <div style="width: 12.66%;" class="table-cell">服务机构</div>
                <div style="width: 8.66%;" class="table-cell">计划时长</div>
                <div style="width: 14.66%;" class="table-cell">开始时间</div>
                <div style="width: 14.66%;" class="table-cell">完成时间</div>
                <div style="width: 4.01%;" class="table-cell" >超时</div>
                <div style="width: 4.01%;" class="table-cell" >缩时</div>
<!--                <div style="width: 4.01%;" class="table-cell" >终止</div>-->
              </div>
              <div style="height: calc(100% - 40px);overflow-y: scroll;" id="mainTable">
                <div :class="{ 'animate-up': animateUp, 'alert-row':item.overtime > 66}" style="width: 100%;display: flex;color: #fff;justify-content: space-around;"
                     v-for="(item, index) in dateDailyYc" :key="index">
                  <div style="width: 10.66%;" class="table-cell">{{item.serviceDate}}</div>
                  <div style="width: 12.66%;" class="table-cell">{{item.measuresNursingName}}</div>
                  <div style="width: 10.66%;" class="table-cell">{{item.customerName}}</div>
                  <div style="width: 10.66%;" class="table-cell">{{item.providerName}}</div>
                  <div style="width: 12.66%;" class="table-cell">{{item.communityName}}</div>
                  <div style="width: 8.66%;" class="table-cell">{{item.duration}}</div>
                  <div style="width: 14.66%;" class="table-cell">{{item.startTime}}</div>
                  <div style="width: 14.66%;" class="table-cell">{{item.endTime}}</div>
                  <div style="width: 4.01%;" class="table-cell">{{item.overtime > 66 ? '★' : ''}}</div>
                  <div style="width: 4.01%;" class="table-cell">{{item.overtime &lt;= 33 ? '★' : ''}}</div>
<!--                  <div style="width: 4.01%;" class="table-cell">{{item.overtime > 33 && item.stop &lt;= 66 ? '★' : ''}}</div>-->
                </div>
              </div>
            </div>
            <!--表 异常工单人次/日 结束-->
            <!--表 服务投诉明细/日 开始-->
            <div v-if="tableNumber === 2" style="width: calc(100% - 10px);height: calc(100% - 10px);overflow-y: scroll;" @mouseover="mouseAction(0)" @mouseleave="mouseAction(1)">
              <div style="width: 100%;display: flex;color: #fff;justify-content: space-around">
                <div style="width: 14.28%;" class="table-cell" >投诉日期</div>
                <div style="width: 14.28%;" class="table-cell" >投诉项目</div>
                <div style="width: 14.28%;" class="table-cell" >投诉人</div>
<!--                <div style="width: 14.28%;" class="table-cell" >被投诉者</div>-->
                <div style="width: 14.28%;" class="table-cell" >被投诉机构</div>
                <div style="width: 14.28%;" class="table-cell" >处理反馈</div>
                <div style="width: 14.28%;" class="table-cell" >备注情况信息</div>
              </div>
              <div style="height: calc(100% - 40px);overflow-y: scroll;" id="mainTable">
                <div :class="{ 'animate-up': animateUp}" style="
              width: 100%;
              display: flex;
              color: #fff;
              justify-content: space-around;" v-for="(item, index) in dateDailyTs" :key="index">
                  <div style="width: 14.28%;" class="table-cell">{{item.complainTime}}</div>
                  <div style="width: 14.28%;" class="table-cell">{{item.service}}</div>
                  <div style="width: 14.28%;" class="table-cell">{{item.nickName}}</div>
<!--                  <div style="width: 14.28%;" class="table-cell">{{item.provider}}</div>-->
                  <div style="width: 14.28%;" class="table-cell">{{item.communityName}}</div>
                  <div style="width: 14.28%;" class="table-cell">{{item.status}}</div>
                  <div style="width: 14.28%;" class="table-cell">{{item.reason}}</div>

                </div>
              </div>
            </div>
            <!--表 服务投诉明细/日 结束-->
            <!--表 高频高额自费项目明细 开始-->
            <div v-if="tableNumber === 3" style="width: calc(100% - 10px);height: calc(100% - 10px);overflow-y: scroll;" @mouseover="mouseAction(0)" @mouseleave="mouseAction(1)">
              <div style="width: 100%;display: flex;color: #fff;justify-content: space-around">
                <div style="width: 12.5%;" class="table-cell" >工单日期</div>
                <div style="width: 12.5%;" class="table-cell" >服务项目</div>
                <div style="width: 12.5%;" class="table-cell" >服务对象</div>
                <div style="width: 12.5%;" class="table-cell" >服务者</div>
                <div style="width: 12.5%;" class="table-cell" >服务机构</div>
                <div style="width: 12.5%;" class="table-cell" >自费金额</div>
                <div style="width: 12.5%;" class="table-cell" >高额自费</div>
                <div style="width: 12.5%;" class="table-cell" >高频自费</div>
              </div>
              <div style="height: calc(100% - 40px);overflow-y: scroll;" id="mainTable">
                <div :class="{ 'animate-up': animateUp}" style="
              width: 100%;
              display: flex;
              color: #fff;
              justify-content: space-around;" v-for="(item, index) in dateDailyGPGE" :key="index">
                  <div style="width: 12.5%;" class="table-cell">{{item.date}}</div>
                  <div style="width: 12.5%;" class="table-cell">{{item.service}}</div>
                  <div style="width: 12.5%;" class="table-cell">{{item.consumers}}</div>
                  <div style="width: 12.5%;" class="table-cell">{{item.provider}}</div>
                  <div style="width: 12.5%;" class="table-cell">{{item.org}}</div>
                  <div style="width: 12.5%;" class="table-cell">{{item.selfPay}}</div>
                  <div style="width: 12.5%;" class="table-cell">{{item.highExpense}}</div>
                  <div style="width: 12.5%;" class="table-cell">{{item.highExpense2}}</div>

                </div>
              </div>
            </div>
            <!--表 高频高额自费项目明细 结束-->
<!--                        <test-table/>-->
          </div>
        </div>

      </div>
      <div class="m-right">
        <div style=" height: calc((100% - 20px) * 0.25);position: relative">
          <!--    居家服务趋势/年    -->
          <div class="title" style="position: relative">居家服务趋势/{{qushiFlag ? '月' : '年'}}
            <div style="width: 24px;height: 24px;border-radius: 5px;
background-color: #152487;display: inline-block;
font-size: 16px;line-height: 24px;border: 1px solid #ffffff;
position: absolute;right: 10px;top: 2.5px;cursor: pointer" :title="qushiTitle"
            @click="qushiToggle()">{{qushiFlag ? '年' : '月'}}</div>
          </div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap" style="height: 70%">
<!--            <age-distribution-radar/>-->
            <school-trends-line2 ref="mychildYear1" v-if="!qushiFlag" chart-id="chart1"/>
            <school-trends-line-month ref="mychildMonth1" v-if="qushiFlag" chart-id="chart1"/>
          </div>
        </div>
        <div style=" height: calc((100% - 20px) * 0.25);position: relative;margin-top: 10px">
          <!--    居家服务趋势/年    -->
          <div class="title">社区服务趋势/{{qushiFlag ? '月' : '年'}}</div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap" style="height: 70%">
            <school-trends-line2  ref="mychildYear2" v-if="!qushiFlag" chart-id="chart2"/>
            <school-trends-line-month ref="mychildMonth2" v-if="qushiFlag" chart-id="chart2"/>
          </div>
        </div>
        <div style=" height: calc((100% - 20px) * 0.25);position: relative;margin-top: 10px">
          <!--    居家服务趋势/年    -->
          <div class="title">机构服务趋势/{{qushiFlag ? '月' : '年'}}</div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap" style="height: 70%">
            <school-trends-line2  ref="mychildYear3" v-if="!qushiFlag" chart-id="chart3"/>
            <school-trends-line-month ref="mychildMonth3" v-if="qushiFlag" chart-id="chart3"/>
          </div>
        </div>
        <div style=" height: calc((100% - 20px) * 0.25);position: relative;margin-top: 10px">
          <!--    居家服务趋势/年    -->
          <div class="title">服务投诉趋势/{{qushiFlag ? '月' : '年'}}</div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap" style="height: 70%">
            <school-trends-line2 ref="mychildYear4" v-if="!qushiFlag" chart-id="chart4"/>
            <school-trends-line-month ref="mychildMonth4" v-if="qushiFlag" chart-id="chart4"/>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  import bt from '@/static/img/标题.png'
  import gg from '@/static/img/边框1.png'
  import lb from '@/static/img/lb.png'
  import content from '@/static/img/content.png'
  import org from '@/static/img/机构.png'
  import hint from '@/static/img/hint.png'
  import content1 from '@/static/img/content1.png'
  import period from './period'
  import ageDistributionRadar from "@/view/trainingSituation/ageDistributionRadar";
  // import degreeDistributionBar from "@/view/trainingSituation/degreeDistributionBar";
  import SchoolTrendsLine from "@/view/trainingSituation/SchoolTrendsLine";
  // import testTable from "@/view/trainingSituation/trainingSituationTable";
  import border from "../../components/border.vue"
  import StudentPassRateBar from './StudentPassRateBar'
  import random_str from "view-design/src/utils/random_str";
  import gpgezfxmmx from "@/view/trainingSituation2/gpgezfxmmx";
  import SchoolTrendsLine2 from "@/view/trainingSituation2/SchoolTrendsLine2";
  import SchoolTrendsLineMonth from "@/view/trainingSituation2/SchoolTrendsLineMonth";
  import currentPeriodGauge2 from "@/view/trainingSituation2/currentPeriodGauge2";
  import yibiaopan from "@/view/trainingSituation2/yibiaopan";
  import barP from "@/view/trainingSituation2/barP";
  import barP2 from "@/view/trainingSituation2/barP2";
  import barP3 from "@/view/trainingSituation2/barP3";
  import { getTwoTopList,getTwoTopBtList,getTwoRightList,getTwoRightYearList,getTwoGdlsList,getYcOrderList,getTwoGdlsListGpGe,
    getTsOrderList} from './trainingSituation'

  export default {
    name: "trainingSituation2",
    components: {
      yibiaopan,
      border,
      period,
      ageDistributionRadar,
      StudentPassRateBar,
      // degreeDistributionBar,
      SchoolTrendsLine,
      // testTable,
      gpgezfxmmx,SchoolTrendsLine2,currentPeriodGauge2,SchoolTrendsLineMonth, barP,barP2,barP3
    },
    data() {
      return {
        centreTop:{},
        zcGdNumber:0,
        centrePie:{},
        jjffYear:[],//居家
        sqffYear:[],//社区服务
        jgffYear:[],//机构服务
        fftsYear:[],//服务投诉
        jjffMonth:[],//居家
        sqffMonth:[],//社区服务
        jgffMonth:[],//机构服务
        fftsMonth:[],//服务投诉
        qushiTitle: '切换为月度趋势',
        qushiFlag: true, // false 年,true 月
        yearList:[],
        testFlag: false,
        number:0,
        leftTableNumbers: [1,2,3],
        tableTitles: ['每日工单流水信息明细表', '异常工单明细/日', '服务投诉明细/日', '高频高额自费项目明细'],
        tableTitle: '每日工单流水信息明细表', // 异常工单明细/日 服务投诉明细/日 高频高额自费项目明细
        tableNumber: 0,
        animatedUp: true,
        mainTableWidth: 400,
        mainTableHeight: 100,
        mainTableRowHeight: 400,
        columnsDaily: [
          { title: "工单日期",    key: "date",        align: "center",},
          { title: "服务项目",    key: "service",     align: "center",},
          { title: "服务对象",    key: "consumers",   align: "center",},
          { title: "服务者",     key: "provider",    align: "center",},
          { title: "服务机构",    key: "org",         align: "center",},
          { title: "计划时长",    key: "duration",    align: "center",},
          { title: "开始时间",    key: "start",       align: "center",},
          { title: "完成时间",    key: "completion",  align: "center",},
          // { title: "工单标记",    key: "mark",        align: "center",}, // 正常工单,超时工单,缩时工单,终止工单
          // { title: "高额自费",    key: "highExpense", align: "center",}, // 是(★),否(_)
          // { title: "自费金额(元)",    key: "selfPay",     align: "center",}, // 自费金额
        ],
        dateDaily: [],
        dateDailyYc: [],
        dateDailyTs: [],//投诉
        dateDailyGPGE: [],//投诉
        bt: bt, //标题图片
        gg: gg, //公告图片
        lb: lb, //喇叭图片
        org: org, //机构图片
        hint: hint, //提示图片
        content: content, //内容图片
        content1: content1,
        value: 0,
        timer: '0',//计时器
        timer1: '0',//
        timerS: '', //
        pwidth: 0,//公告文本的宽度
        animateUp: false,
        windowWidth: document.documentElement.clientWidth,// 设备屏幕的宽度
        height: window.innerHeight + 'px',
        /*服务项目 服务对象 服务者/机构 计划时长 开始时间 完成时间*/
        yuanData: [
          {  name: '项目' + random_str(5),dqjss: random_str(5),dqxys: random_str(5),ljzsrs: parseInt(Math.random()*1000),ljkbsl: '455',ljbkrs: '44'},
          {  name: '项目' + random_str(5),dqjss: random_str(5),dqxys: random_str(5),ljzsrs: parseInt(Math.random()*1000),ljkbsl: '455',ljbkrs: '44'},
        ]
      }
    },
    watch: {

    },
    methods: {
      getFun(){
        getTwoTopList().then(res =>{
          let _ = this
          this.centreTop = res;
          _.zcGdNumber = _.centreTop.orderGDVO.number - _.centreTop.orderYCVO.number;
          setTimeout(() => {
            _.$refs.barP.initChart(_.centreTop.orderGDVO.sm,_.centreTop.orderGDVO.jg);
            let zcsm = _.centreTop.orderGDVO.sm -_.centreTop.orderYCVO.sm;
            let zcjg = _.centreTop.orderGDVO.jg -_.centreTop.orderYCVO.jg;
            let sum = zcsm + zcjg;
            zcjg = parseInt(zcjg/sum) * 100;
            zcsm = 100 - zcjg;
            _.$refs.barP2.initChart(zcsm, zcjg);
            _.$refs.barP3.initChart(_.centreTop.orderYCVO.sm,_.centreTop.orderYCVO.jg);
          }, 1500);
        })
        getTwoTopBtList().then(res =>{
          this.centrePie = res;
        })
        //每日工单流水信息明细表
        getTwoGdlsList().then(res =>{
          this.dateDaily = res;
        })

        getYcOrderList().then(res1 =>{
          this.dateDailyYc = [];
          this.dateDailyYc = res1;
        })
        getTsOrderList().then(res1 =>{
          this.dateDailyTs = [];
          this.dateDailyTs = res1;
        })
        getTwoGdlsListGpGe().then(res1 =>{
          this.dateDailyGPGE = [];
          this.dateDailyGPGE = res1;
        })

      },
      qushiToggle() {
        console.log("屏二方法")
        this.qushiFlag = !this.qushiFlag;
        if(!this.qushiFlag) {
          this.qushiTitle = '切换为年度趋势'
          getTwoRightYearList().then((res) =>{
            let list = [res][0];
            let _ = this;
            this.jjffYear = []
            this.sqffYear = []
            this.jgffYear = []
            this.fftsYear = []
            list.forEach(function(value) {
              if(value.name == '居家服务趋势/年'){
                _.jjffYear.push(value.value1)
                _.jjffYear.push(value.value2)
                _.jjffYear.push(value.value3)
                _.jjffYear.push(value.value4)
                _.jjffYear.push(value.value5)
                _.jjffYear.push(value.value6)
                _.jjffYear.push(value.value7)
                _.jjffYear.push(value.value8)
              }
              if(value.name == '社区服务趋势/年'){
                _.sqffYear.push(value.value1)
                _.sqffYear.push(value.value2)
                _.sqffYear.push(value.value3)
                _.sqffYear.push(value.value4)
                _.sqffYear.push(value.value5)
                _.sqffYear.push(value.value6)
                _.sqffYear.push(value.value7)
                _.sqffYear.push(value.value8)
              }
              if(value.name == '机构服务趋势/年'){
                _.jgffYear.push(value.value1)
                _.jgffYear.push(value.value2)
                _.jgffYear.push(value.value3)
                _.jgffYear.push(value.value4)
                _.jgffYear.push(value.value5)
                _.jgffYear.push(value.value6)
                _.jgffYear.push(value.value7)
                _.jgffYear.push(value.value8)
              }
              if(value.name == '服务投诉趋势/年'){
                _.fftsYear.push(value.value1)
                _.fftsYear.push(value.value2)
                _.fftsYear.push(value.value3)
                _.fftsYear.push(value.value4)
                _.fftsYear.push(value.value5)
                _.fftsYear.push(value.value6)
                _.fftsYear.push(value.value7)
                _.fftsYear.push(value.value8)
              }
            });
            setTimeout(() => {
              this.$refs.mychildYear1.initChart(this.jjffYear);
            }, 1500);
            setTimeout(() => {
              this.$refs.mychildYear2.initChart(this.sqffYear);
            }, 1500);
            setTimeout(() => {
              this.$refs.mychildYear3.initChart(this.jgffYear);
            }, 1500);
            setTimeout(() => {
              this.$refs.mychildYear4.initChart(this.fftsYear);
            }, 1500);




          })
          this.qushiFlag = !this.qushiFlag;
          this.qushiFlag = !this.qushiFlag;
        } else {
          this.qushiTitle = '切换为月度趋势'
          getTwoRightList().then(res =>{
            let list = [res][0];
            let _ = this;
            this.jjffMonth = []
            this.sqffMonth = []
            this.jgffMonth = []
            this.fftsMonth = []
            list.forEach(function(value) {
              if(value.name == '居家服务趋势/月'){
                _.jjffMonth.push(value.value1)
                _.jjffMonth.push(value.value2)
                _.jjffMonth.push(value.value3)
                _.jjffMonth.push(value.value4)
                _.jjffMonth.push(value.value5)
                _.jjffMonth.push(value.value6)
                _.jjffMonth.push(value.value7)
                _.jjffMonth.push(value.value8)
                _.jjffMonth.push(value.value9)
                _.jjffMonth.push(value.value10)
                _.jjffMonth.push(value.value11)
                _.jjffMonth.push(value.value12)
              }
              if(value.name == '社区服务趋势/月'){
                _.sqffMonth.push(value.value1)
                _.sqffMonth.push(value.value2)
                _.sqffMonth.push(value.value3)
                _.sqffMonth.push(value.value4)
                _.sqffMonth.push(value.value5)
                _.sqffMonth.push(value.value6)
                _.sqffMonth.push(value.value7)
                _.sqffMonth.push(value.value8)
                _.sqffMonth.push(value.value9)
                _.sqffMonth.push(value.value10)
                _.sqffMonth.push(value.value11)
                _.sqffMonth.push(value.value12)
              }
              if(value.name == '机构服务趋势/月'){
                _.jgffMonth.push(value.value1)
                _.jgffMonth.push(value.value2)
                _.jgffMonth.push(value.value3)
                _.jgffMonth.push(value.value4)
                _.jgffMonth.push(value.value5)
                _.jgffMonth.push(value.value6)
                _.jgffMonth.push(value.value7)
                _.jgffMonth.push(value.value8)
                _.jgffMonth.push(value.value9)
                _.jgffMonth.push(value.value10)
                _.jgffMonth.push(value.value11)
                _.jgffMonth.push(value.value12)
              }
              if(value.name == '服务投诉趋势/月'){
                _.fftsMonth.push(value.value1)
                _.fftsMonth.push(value.value2)
                _.fftsMonth.push(value.value3)
                _.fftsMonth.push(value.value4)
                _.fftsMonth.push(value.value5)
                _.fftsMonth.push(value.value6)
                _.fftsMonth.push(value.value7)
                _.fftsMonth.push(value.value8)
                _.fftsMonth.push(value.value9)
                _.fftsMonth.push(value.value10)
                _.fftsMonth.push(value.value11)
                _.fftsMonth.push(value.value12)
              }
            });
            setTimeout(() => {
              this.$refs.mychildMonth1.initChart(this.jjffMonth);
            }, 1500);
            setTimeout(() => {
              this.$refs.mychildMonth2.initChart(this.sqffMonth);
            }, 1500);
            setTimeout(() => {
              this.$refs.mychildMonth3.initChart(this.jgffMonth);
            }, 1500);
            setTimeout(() => {
              this.$refs.mychildMonth4.initChart(this.fftsMonth);
            }, 1500);
          })
        }
      },
      cancelAlert() {
        this.testFlag = false;
      },
      tableChange(leftNumber) { // 0,1,2
        let temp = JSON.parse(JSON.parse(this.tableNumber));
        this.tableNumber = this.leftTableNumbers[leftNumber];
        this.leftTableNumbers[leftNumber] = temp;
      },
      test3() {
      },
      mouseAction(flag) {
        if(flag === 1) {
          this.timer1 = setInterval(this.scrollAnimate, 1500);
        } else {
          clearInterval(this.timer1)
        }
      },
      clickCommend(e) {

      },
      menter() {
        clearInterval(this.timer);
      },
      mleave() {
        this.timer = setInterval(this.clickCommend, 0);
      },
      scrollAnimate() {
        // 滚动到顶部
        let table = document.getElementById('mainTable');
        table.scrollTop = 0;
        this.animateUp = true;
        this.timerS = setTimeout(() => {
          this.dateDaily.push(this.dateDaily[0]);
          this.dateDailyYc.push(this.dateDailyYc[0]);
          this.dateDaily.shift();
          this.dateDailyYc.shift();
          this.animateUp = false
        }, 500)
      },
      testForData(num) {

        for(let i=1;i<num;i++) {
          this.dateDaily.push(
              { date: '2099-12-24', service: '项目' + random_str(5), consumers: '顾客' + i, provider: '护理' + random_str(4),
                org: '机构' + random_str(5), duration: parseInt(Math.random()*1000),
                start: '201-12-24', completion: '', mark: '正常', highExpense: '★', selfPay: '300', fine: parseInt(Math.random()*100),
                overtime: parseInt(Math.random()*100),undertime: parseInt(Math.random()*100),stop: parseInt(Math.random()*100),
              }
          )
        }
      },
    },
    destroyed() {
      clearInterval(this.timer);
      clearInterval(this.timer1)
    },
    mounted() {
      // this.testForData(30)
      // this.timer = setInterval(this.clickCommend, 10);
      this.timer1 = setInterval(this.scrollAnimate, 1500);
      let that = this;
      setTimeout(()=> {
        that.$forceUpdate()

      },1800);

      this.timer = setInterval(()=>{
        this.testFlag = !this.testFlag
      }, 20000);
      this.getFun();
      this.qushiToggle();
    },
  }
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;height: 0;
}

  .head {
    background: radial-gradient(ellipse, #00102E 0, #000B27 100%);
    height: 100%;
    overflow-y: hidden;

  }

  .bt {
    background-image: url("../../static/imgs/标题.png");
    background-size: 100% 45px;
    background-repeat: no-repeat;
    background-position: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;

  }

  .bt1 {
    color: rgb(0, 255, 255);
    font-size: 27px;

  }

  .box {
    width: calc(100% - 20px);
    border: 1px solid #2163CA;
    padding-left: 55px;
    padding-right: 20px;
    height: 45px;
    margin: auto 10px auto auto;
    position: relative;
    box-shadow: inset 0 0 20px 1px #2163CA;

    &::before {
      position: absolute;
      left: 10px;
      top: 10px;
      content: " ";
      width: 25px;
      height: 25px;
      background-image: url("../../static/imgs/lb.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }

    /*公告内容*/
    .marquee-box {
      overflow: auto;

      .pWidth {
        line-height: 45px;
        color: rgb(0, 255, 255);
        display: block;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        font-family: 微软雅黑, serif;
        fontSize: 14px;
      }
    }
  }

  .middle {
    display: flex;
    padding: 0 10px;
    height: calc(100% - 80px);
    //margin-top: 10px;

    .m-left {
      width: calc((100% - 20px) * 0.19);
      display: flex;
      flex-direction: column;
    }

    .m-middle {
      width: calc((100% - 20px) * 0.62);
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
    }

    .m-right {
      width: calc((100% - 20px) * 0.19);
      display: flex;
      flex-direction: column;
    }
  }

  .echart-wrap {
    position: absolute;
    top: 50px;
    z-index: 999;
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;

  }


  .title {
    color: white;
    background-color: rgb(0, 26, 77);
    text-align: center;
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  /*招生人次*/
  .admissionsPeople {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  .admissionsPeople1 {
    width: 100%;
    height: calc(100% - 40px);
    background-color: rgb(0, 20, 61);
    position: relative;
  }

  /*学员合格率*/
  .StudentPassRate {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  /*学员合格率*/
  .StudentPassRate1 {
    width: 100%;
    height: 100%;

    position: relative;
  }

  .StudentPassRate2 {
    position: absolute;
    z-index: 999;
    bottom: 0;
    left: 0;

  }

  /*招生，报考，结业趋势*/
  .tendency {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  .tendency1 {
    width: 100%;
    height: 100%;
    position: relative;

  }

  .tendency2 {
    width: 94%;
    height: 90%;

    position: absolute;
    bottom: 0;

    left: 0;

  }

  /*居家服务趋势/年*/
  .ageDistribution {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  .ageDistribution1 {
    width: 100%;
    height: 100%;

    position: relative;


  }

  .ageDistribution2 {
    width: 92%;
    height: 92%;

    position: absolute;
    bottom: 0;

    left: 2%;

  }

  /*教师学员学历分布*/
  .degreeDistribution {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  .degreeDistribution1 {
    width: 100%;
    height: 100%;

    position: relative;

  }

  .degreeDistribution2 {
    width: 92%;
    height: 92%;

    position: absolute;
    bottom: 0;

    left: 2%;

  }

  /*学时趋势*/
  .SchoolTrends {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  .SchoolTrends1 {
    width: 100%;
    height: 100%;

    position: relative;


  }

  .SchoolTrends2 {
    width: 92%;
    height: 88%;

    position: absolute;
    bottom: 5%;

    left: 2%;

  }

  /*机构*/
  .org {
    width: 100%;
    background-color: rgb(0, 18, 53);
    height: calc((100% - 10px) * 0.38);
    position: relative;

    //&::before {
    //  content: " ";
    //  position: absolute;
    //  top: 0;
    //  bottom: 0;
    //  left: 20%;
    //  right: 0;
    //  margin: auto;
    //  background-image: url("../../static/img/机构.png");
    //  background-size: 60% auto;
    //  background-repeat: no-repeat;
    //  width: 100%;
    //  height: 100%;
    //}

    .hint {
      width: 130px;
      height: 50px;
      position: relative;
      top: 11%;
      left: 62%;
    }

    .hint1 {
      width: 130px;
      height: 50px;
      position: absolute;
      color: rgb(0, 255, 255);
      top: 20%;
      left: 25%;
    }

    .desc-wrap {
      //position: absolute;
      //top: 15px;
      //bottom: 15px;
      left: 0;
      width: 100%;
      padding: 0 10px;
      height: 30%;
      display: flex;
      justify-content: space-around;

    }

    .desc-wrap-row2 {
      //top: 137px;
      height: 70%;
    }

    .desc {
      background-image: url("../../static/img/content1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 10px;
      padding-left: 2px;
      padding-right: 6px;
      width: calc(100% / 4 - 15px);

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .number {
        color: rgb(0,255,255);
        font-size:22px;
      }
      .desc-title {
        color: rgb(0,255,255);
        font-size:22px;
      }
    }

    .desc-row2 {
      width: calc(100% / 3 - 15px);
    }

  }


  /*机构服务人次/年*/
  .AttendanceMonth {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 6%;

  }

  /*社区服务人次/年*/
  .classesSequential {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 29%;

  }

  /*居家服务人次/年*/
  .MonthlyApplicants {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 53%;

  }

  /*服务投诉人次/年*/
  .PerCapitaClassHours {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 77%;

  }

  .column {
    width: 100%;
    height: 100%;

    position: relative;


  }

  .column1 {
    width: 95%;
    height: 87%;

    position: absolute;
    top: 9%;

    left: 2%;

  }

  .AttendanceMonth1 {

    position: absolute;
    top: 10%;

    left: 16%;

  }


  /*教师数量*/
  .NumberOfTeachers {
    width: 130px;
    height: 50px;
    position: relative;
    top: 10%;
    left: 15%;

  }

  .NumberOfTeachers1 {
    width: 130px;
    height: 50px;

    position: absolute;
    color: rgb(0, 255, 255);

    top: 20%;

    left: 18%;

  }

  .NumberOfTeachers img {

    transform: rotateY(180deg);

  }

  /*招生人次*/
  .inset {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 6%;
    font-size: 23px;
    text-align: center;

  }

  .inset1 {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 21%;
    font-size: 23px;
    text-align: center;

  }

  .inset2 {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 36%;
    font-size: 23px;
    text-align: center;

  }

  .inset3 {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 52%;
    font-size: 23px;
    text-align: center;

  }

  .inset4 {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 68%;
    font-size: 23px;
    text-align: center;

  }

  .inset5 {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 84%;
    font-size: 23px;
    text-align: center;

  }

  .list-child {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 14px;
    color: white;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    div{
      flex: 1;
      text-align: center;
    }
  }

  .marquee-wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;

    .marquee-list {
      li {
        width: 100%;
        height: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 20px;
        list-style: none;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
      }
    }
    .animate-up {
      transition: all 0.5s ease-in-out;
      transform: translateY(-40px);
    }
  }

  .animate-up {
    transition: all 0.5s ease-in-out;
    transform: translateY(-40px);
  }

.animate-up-small-table {
  transition: all 0.5s ease-in-out;
  transform: translateY(-20px);
}

.table-cell {
  height: 40px;line-height: 40px;text-align: center;
  white-space: nowrap;
}

.alert-row {
  background-color: #b10000;
}
</style>

<style>


  /*全局滚动条样式*/
  /*::-webkit-scrollbar {*/
  /*  width: 5px;*/
  /*  height: 14px;*/
  /*}*/

  /*::-webkit-scrollbar-thumb {*/
  /*  background-color: #01c8dc;*/
  /*  border-radius: 3px;*/
  /*  background-color: rgba(250,250,250,.5);*/
  /*}*/
</style>
